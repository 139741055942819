/* eslint-disable max-lines */
/* eslint-disable no-nested-ternary */
import { ReactElement } from 'react';
import { mean as average } from 'lodash';
import Bar from './Bar/Bar';
import * as S from './FeedbackPrint.styles';

import { Cohort, Participant } from 'services/types/DashboardTypes';
import { CohortParticipantResponse } from 'services/types/ParticipantDashboard';
import { Target } from 'components/atoms/Icon/Icon';

interface IProps {
  participant: Participant;
  cohort: Cohort | undefined;
  selfNumericTitle: string;
  assessorsNumericTitle: string;
}

const FeedbackPrint = ({
  participant,
  cohort,
  selfNumericTitle,
  assessorsNumericTitle,
}: IProps): ReactElement => {
  return (
    <S.Feedback>
      <S.FrontPage>
        <S.StyledLogo />
        <S.FrontPageContent>
          <S.StyledLogoFull />
          <S.FrontPageTitle>
            <span>360</span> Mirror
          </S.FrontPageTitle>
          <S.FrontPageFooter>
            <div>{participant.name}</div>
            <div>
              {new Date(
                (cohort as unknown as CohortParticipantResponse).cohort_survey.start_date,
              ).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
              })}
            </div>
          </S.FrontPageFooter>
        </S.FrontPageContent>
      </S.FrontPage>
      <S.Section>
        <S.SectionHeader>
          <S.StyledTHNK />
        </S.SectionHeader>
        <S.SectionBanner>
          <S.SectionTitle>The 360 mirror</S.SectionTitle>
          <p style={{ marginTop: '6pt' }}>
            “
            <i>
              It is the obvious which is so difficult to see most of the time. People say
              &apos;It&apos;s as plain as the nose on your face.&apos; But how much of the nose on
              your face can you see, unless someone holds a mirror up to you?
            </i>
            ” - Isaac Asimov, I Robot
          </p>
        </S.SectionBanner>
        <S.SectionContent>
          <p>
            Leadership development is a journey with no fixed destination. Just like an asymptote in
            geometry gets closer and closer to an axis but never reaches it, you can get closer to
            your destination and you will always have an opportunity to develop further. To get
            closer to that destination, you first need to know where you are now and what steps you
            want to take to get closer. This report tells you about your leadership qualities,
            strengths and your development potential, perceived by you and by others. Let&apos;s
            begin!
          </p>
          <S.SectionSubTitle style={{ marginTop: '12pt' }}>
            How to read this report
          </S.SectionSubTitle>
          <p style={{ marginTop: '12pt' }}>
            Every person has a unique set of gifts and opportunities. This assessment allows you to
            discover yours. Look for areas where your self-scoring significantly differs from how
            other people rated you and then consider why these ratings are different. For example,
            if others scored you more highly on a leadership quality than you scored yourself, then
            maybe you have a hidden gift. And conversely, if you rated yourself highly but others
            rated you less favorably, then you may have a blind spot. Start with the big picture,
            your score across the elements. Then zoom in on each leadership quality. At the end of
            this report you&apos;ll find a section to reflect on your strengths and areas for
            development.
          </p>
        </S.SectionContent>
      </S.Section>
      <S.Section>
        <S.SectionHeader>
          <S.StyledTHNK />
        </S.SectionHeader>
        <S.SectionBanner>
          <S.SectionTitle>Overall results</S.SectionTitle>
        </S.SectionBanner>
        <S.SectionContent>
          {participant.assessment?.elements.map((element) => {
            if (
              !element.questions.some((question) => {
                return question.no_answer;
              })
            )
              return null;
            const selfAverage = Math.round(
              average(
                element.questions
                  .filter((question) => question.no_answer)
                  .map((question) => {
                    return question?.self_answer;
                  })
                  ?.filter((answer) => answer !== -1),
              ),
            );
            const x = 1 + (selfAverage / 100) * 4;
            const displaySelfAverage = x % 1 === 0 ? x : parseFloat(x.toFixed(1));
            const assessorsAvegare = Math.round(
              average(
                element.questions
                  .filter((question) => question.no_answer)
                  .map((question) => {
                    return question?.assessor_answers;
                  })
                  ?.flat(1)
                  ?.filter((answer) => answer !== -1),
              ),
            );
            const y = 1 + (assessorsAvegare / 100) * 4;
            const displayAssessorsAverage = y % 1 === 0 ? y : parseFloat(y.toFixed(1));
            return (
              <S.QuestionContainer key={element.name}>
                <S.QuestionInfo>
                  <S.SectionSubTitle>{element.name}</S.SectionSubTitle>
                  {!element.description ? null : (
                    <div style={{ marginTop: '6pt' }}>{element.description}</div>
                  )}
                </S.QuestionInfo>
                <S.AnswerContainer>
                  {selfAverage === -1 ? null : (
                    <S.Answer>
                      <S.AnswerHeader>
                        <div>{selfNumericTitle}</div>
                        <S.Score>{displaySelfAverage}</S.Score>
                      </S.AnswerHeader>
                      <Bar score={displaySelfAverage} />
                    </S.Answer>
                  )}
                  <S.Answer>
                    <S.AnswerHeader>
                      <div>{assessorsNumericTitle}</div>
                      <S.Score>{displayAssessorsAverage}</S.Score>
                    </S.AnswerHeader>
                    <Bar score={displayAssessorsAverage} />
                  </S.Answer>
                </S.AnswerContainer>
              </S.QuestionContainer>
            );
          })}
        </S.SectionContent>
      </S.Section>
      <S.Section>
        <S.SectionHeader>
          <S.StyledTHNK />
        </S.SectionHeader>
        <S.SectionBanner>
          <S.SectionTitle>Your full report</S.SectionTitle>
        </S.SectionBanner>
        <S.SectionContent>
          {participant.assessment?.elements.map((element) => {
            return (
              <S.SectionChunk key={element.name}>
                <S.ChunkTitle>{element.name}</S.ChunkTitle>
                {element.questions.map((question) => {
                  const openQuestion = !question.no_answer;
                  const assessorsAvegare = openQuestion
                    ? question.assessor_answers.map((answer) => (
                        <div key={question.title}>{answer}</div>
                      ))
                    : Math.round(
                        average(
                          question?.assessor_answers?.flat(1)?.filter((answer) => {
                            return answer !== -1;
                          }),
                        ),
                      );

                  const y = 1 + (+assessorsAvegare / 100) * 4;
                  const displayAssessorsAvegare = y % 1 === 0 ? y : parseFloat(y.toFixed(1));

                  const x = 1 + (+question.self_answer / 100) * 4;
                  const displaySelfAnswer =
                    question.self_answer === -1
                      ? question.self_answer
                      : x % 1 === 0
                      ? x
                      : parseFloat(x.toFixed(1));

                  return (
                    <S.QuestionContainer key={question.title}>
                      <S.QuestionInfo>
                        <S.SectionSubTitle>{question.title}</S.SectionSubTitle>
                        <div style={{ marginTop: '6pt' }}>{question.content}</div>
                      </S.QuestionInfo>
                      <S.AnswerContainer>
                        {openQuestion ? (
                          <S.Answer>
                            <S.AnswerHeader>
                              <div>Your response</div>
                            </S.AnswerHeader>
                            <S.AnswerWrapper>{question.self_answer}</S.AnswerWrapper>
                          </S.Answer>
                        ) : question.self_answer === -1 ? null : (
                          <S.Answer>
                            <S.AnswerHeader>
                              <div>{selfNumericTitle}</div>
                              <S.Score>{displaySelfAnswer}</S.Score>
                            </S.AnswerHeader>
                            <Bar score={displaySelfAnswer as number} />
                          </S.Answer>
                        )}

                        <S.Answer>
                          <S.AnswerHeader>
                            {openQuestion ? (
                              <div>Responses from others</div>
                            ) : (
                              <div>{assessorsNumericTitle}</div>
                            )}
                            {openQuestion ? null : <S.Score>{displayAssessorsAvegare}</S.Score>}
                          </S.AnswerHeader>
                          {openQuestion ? (
                            <div style={{ marginTop: '6pt' }}>
                              {question.assessor_answers.map((answer, index) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <S.AnswerWrapper key={`${answer}${index}`}>
                                  <S.OpenAnswer>{answer}</S.OpenAnswer>
                                </S.AnswerWrapper>
                              ))}
                            </div>
                          ) : (
                            <Bar score={displayAssessorsAvegare as number} />
                          )}
                        </S.Answer>
                      </S.AnswerContainer>
                    </S.QuestionContainer>
                  );
                })}
              </S.SectionChunk>
            );
          })}
        </S.SectionContent>
      </S.Section>
      <S.Section>
        <S.SectionHeader>
          <S.StyledTHNK />
        </S.SectionHeader>
        <S.SectionBanner>
          <S.SectionTitle>Let&apos;s reflect: your strengths</S.SectionTitle>
        </S.SectionBanner>
        <S.SectionContent>
          <p>
            If you haven&apos;t already, read through the results of your 360 Mirror. The Mirror
            tells you about your leadership qualities, your strengths, and your development
            potential. What do you see? How do others see you? We invite you to take some time to
            answer the following questions and reflect further on your gifts and learning
            opportunities.
          </p>
          <p style={{ marginTop: '12pt', fontWeight: 500 }}>
            Looking at your results, select three areas where the ratings or comments made{' '}
            <S.Highlight>you feel proud</S.Highlight>. Write these areas below and reflect on why
            each one pleased you.
          </p>
          <S.GridContainer>
            <S.Note>
              <span>1</span>
            </S.Note>
            <S.Note>
              <span>2</span>
            </S.Note>
            <S.Note>
              <span>3</span>
            </S.Note>
            <S.Conclusion>
              <div>
                Looking at those three areas that pleased you, what conclusions can you draw about
                your <S.Highlight>strengths</S.Highlight>?
              </div>
              <S.Dots></S.Dots>
            </S.Conclusion>
            <S.Skills>
              <div>
                What skills or qualities can you lean into more in your leadership to{' '}
                <S.Highlight>leverage your strengths?</S.Highlight>
              </div>
              <S.SkillsNote>
                <div />
              </S.SkillsNote>
            </S.Skills>
          </S.GridContainer>
        </S.SectionContent>
      </S.Section>
      <S.Section>
        <S.SectionHeader>
          <S.StyledTHNK />
        </S.SectionHeader>
        <S.SectionBanner>
          <S.SectionTitle>Let&apos;s reflect: your growth areas</S.SectionTitle>
        </S.SectionBanner>
        <S.SectionContent>
          <p>
            Now look back at your results and identify three areas where the ratings or{' '}
            <S.Highlight>comments bothered you</S.Highlight>. Write these areas below and reflect on
            why each one displeased you.
          </p>
          <S.GridContainer>
            <S.Note>
              <span>1</span>
            </S.Note>
            <S.Note>
              <span>2</span>
            </S.Note>
            <S.Note>
              <span>3</span>
            </S.Note>
            <S.Conclusion>
              <div>
                Looking at these three areas, what insights can you draw about your{' '}
                <S.Highlight>areas for growth</S.Highlight>?
              </div>
              <S.Dots></S.Dots>
            </S.Conclusion>
            <S.Skills>
              <div>
                Are there any concrete things you&apos;d like to{' '}
                <S.Highlight>do differently</S.Highlight> moving forward?
              </div>
              <S.SkillsNote>
                <div />
              </S.SkillsNote>
            </S.Skills>
          </S.GridContainer>
          <p style={{ marginTop: '12pt' }}>
            Take a moment and think about what you&apos;ve just written. Perhaps you knew some of
            this already and perhaps something new came up. Before moving on to the next page, pause
            to consider how you feel in this moment.
          </p>
          <S.SectionQuoteContainer>
            <S.SectionQuote>
              Without <S.Highlight>reflection</S.Highlight>, we go blindly on our way.
            </S.SectionQuote>
            <S.SectionQuoteAuthor>Margaret J. Wheatley</S.SectionQuoteAuthor>
          </S.SectionQuoteContainer>
        </S.SectionContent>
      </S.Section>
      <S.Section>
        <S.SectionHeader>
          <S.StyledTHNK />
        </S.SectionHeader>
        <S.SectionBanner>
          <S.SectionTitle>Let&apos;s reflect: your objectives</S.SectionTitle>
        </S.SectionBanner>
        <S.SectionContent>
          <p>
            What specific objectives would you like to set for yourself moving forward to help you
            lean into your strengths and grow the areas you identified?
          </p>
          <S.GridContainerAlt>
            <S.TargetNote>
              <Target />
              <div />
            </S.TargetNote>
            <S.TargetNote>
              <Target />
              <div />
            </S.TargetNote>
            <S.TargetNote>
              <Target />
              <div />
            </S.TargetNote>
            <S.ConclusionAlt>
              <div>
                How will you hold yourself accountable to these objectives? Who might be able to
                help you?
              </div>
              <S.ConclusionAltNote />
            </S.ConclusionAlt>
            <S.Objectives>
              <S.ObjectivesHeader>
                What is <S.Highlight>one small step</S.Highlight> you can take today to get closer
                to your objectives?
              </S.ObjectivesHeader>
              <S.ObjectivesDots />
            </S.Objectives>
          </S.GridContainerAlt>
        </S.SectionContent>
      </S.Section>
      <S.Footer>
        <S.StyledMonochrome />
        <S.Quote>
          Knowing yourself is <S.Highlight>the beginning</S.Highlight> of all wisdom.
        </S.Quote>
        <S.Author>Aristotle</S.Author>
      </S.Footer>
    </S.Feedback>
  );
};

export default FeedbackPrint;
